import React, { Component } from 'react';
import BigCalendar from 'react-big-calendar';
import CustomLoader from '../../../../components/ui/Loader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import chroma from 'chroma-js';
import moment from 'moment';
import Event from './Event';
import DayWrapper from './DayWrapper';
import Toolbar from './Toolbar';
import WorkOnPublicHolidayModal from '../../../../components/modals/WorkOnPublicHolidayModal';
import { formatDate } from '../../../../utils/Date';
import '../../../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import {
  toggleRequestModal,
  setRequestModalInitialState,
} from '../../../../redux/actions/uiActions';
import { fetchAvailableVacations } from '../../../../redux/actions/signedInUserVacationsActions';
import { CALENDARS, CALENDAR_COLORS } from '../../../../constants';
import {
  checkWorkOnHolidayRequest,
  submitWorkOnHolidayRequest,
} from '../../../../redux/actions/publicHolidaysActions';

const localizer = BigCalendar.momentLocalizer(moment);
moment.locale('ko', {
  week: {
    dow: 1,
  },
});
BigCalendar.momentLocalizer(moment);

let components = {
  event: Event,
  dayWrapper: DayWrapper,
  toolbar: Toolbar,
};

class Calendar extends Component {
  state = {
    startDate: new Date(),
    endDate: new Date(),
    showWorkOnPublicHolidayModal: false,
    publicHolidayName: '',
    publicHolidayDate: new Date(),
    formattedPublicHolidayDate: '',
  };

  toggleWorkOnPublicHolidayModal = () => {
    this.setState({
      showWorkOnPublicHolidayModal: !this.state.showWorkOnPublicHolidayModal,
    });
  };

  isPublicHoliday = date => {
    return this.props.publicHolidays.find(holiday => holiday.date === date);
  };

  onSubmitWorkOnPublicHoliday = () => {
    this.props.submitWorkOnHolidayRequest({
      name: this.state.publicHolidayName,
      date: this.state.publicHolidayDate,
    });
    this.toggleWorkOnPublicHolidayModal();
  };

  checkWorkOnHolidayCallback = data => {
    const { name, date } = data;
    this.setState(
      {
        publicHolidayName: name,
        publicHolidayDate: date,
        formattedPublicHolidayDate: formatDate(date, 'D MMM YYYY'),
      },
      this.toggleWorkOnPublicHolidayModal,
    );
  };

  onSelectSlot = slotInfo => {
    const { selectedCalendar } = this.props;
    if (selectedCalendar !== CALENDARS.vacations) return;

    if (slotInfo.start !== slotInfo.end) {
      this.openRequestVacationModal(slotInfo.start, slotInfo.end);
      return;
    }

    const selectedDate = formatDate(slotInfo.start, 'YYYY-MM-DD');
    const isPublicHoliday = this.isPublicHoliday(selectedDate);
    if (isPublicHoliday) {
      this.props.checkWorkOnHolidayRequest(selectedDate, this.checkWorkOnHolidayCallback, () =>
        this.openRequestVacationModal(slotInfo.start, slotInfo.end),
      );
    } else {
      this.openRequestVacationModal(slotInfo.start, slotInfo.end);
    }
  };

  openRequestVacationModal = (startDate, endDate) => {
    const year = startDate.getFullYear();
    this.props.setRequestModalInitialState({
      range: {
        range1: { startDate, endDate },
      },
    });
    this.props.fetchAvailableVacations(this.props.signedInUser.id, { year });
    this.props.toggleRequestModal();
  };

  endAccessor = event => {
    const endDate = new Date(event.endDate);
    const userTimezoneOffset = endDate.getTimezoneOffset() / 60 + 1;
    endDate.setHours(endDate.getHours() + userTimezoneOffset);
    return endDate;
  };

  startAccessor = event => {
    const startDate = new Date(event.startDate);
    const userTimezoneOffset = startDate.getTimezoneOffset() / 60;
    startDate.setHours(startDate.getHours() + userTimezoneOffset);
    return startDate;
  };

  eventPropGetter = event => {
    const eventColor = event.vacationTypeColor || CALENDAR_COLORS.defaultEvent;
    const isFirstYear = event.type === 'anniversary' && event.years === 0;
    const anniversaryColor = isFirstYear
      ? CALENDAR_COLORS.firstYearAnniversary
      : CALENDAR_COLORS.anniversary;

    // default event props
    const eventProps = {
      className: 'vacationEvent',
      style: {
        order: 3,
        backgroundColor: chroma(eventColor)
          .alpha(0.6)
          .css(),
      },
    };
    event.userRights = this.props.userRights;

    // update event props based on event type
    if (event.vacationType === 'publicHoliday') {
      eventProps.className = 'publicHolidayEvent';
      eventProps.style.order = 2;
      delete eventProps.style.backgroundColor;
    }

    if (
      event.vacationType === 'publicHolidayExtra' ||
      event.vacationType === 'publicHolidayCountriesExtra'
    ) {
      eventProps.className = 'publicHolidayExtraEvent';
      eventProps.style.order = 2;
      delete eventProps.style.backgroundColor;
    }

    if (['anniversary', 'birthday'].includes(event.type)) {
      eventProps.className = 'anniversaryEvent';
      eventProps.style.order = 2;
      eventProps.style.backgroundColor =
        event.type === 'anniversary'
          ? chroma(anniversaryColor)
              .alpha(0.8)
              .css()
          : chroma(CALENDAR_COLORS.birthday)
              .alpha(0.8)
              .css();
    }

    return eventProps;
  };

  dayPropGetter = event => {
    const { selectedCalendar, publicHolidays } = this.props;
    let isHoliday = false;
    let isYourHoliday = false;
    let isWeekend = false;
    const currentCalendarDay = new Date(event);
    const dayFormatted = currentCalendarDay.toLocaleDateString('en-CA');

    publicHolidays.forEach(h => {
      if (selectedCalendar !== CALENDARS.vacations) return;
      if (h.date === dayFormatted) {
        isHoliday = true;

        if (h.vacationType === 'publicHoliday') {
          isYourHoliday = true;
        }
      }
    });

    if (currentCalendarDay.getDay() === 6 || currentCalendarDay.getDay() === 0) {
      isWeekend = true;
    }

    let className = '';
    if (isHoliday) className += 'publicHoliday';
    if (!isYourHoliday) className += 'Extra'; // publicHolidayExtra css class
    if (isWeekend) className += ' weekEnd';
    return { className };
  };

  render() {
    const {
      publicHolidayName,
      formattedPublicHolidayDate,
      showWorkOnPublicHolidayModal,
    } = this.state;
    const {
      selectedCalendar,
      dashboardVacations,
      dashboardAnniversaries,
      isFetching,
      publicHolidays,
    } = this.props;
    const displayedEvents =
      selectedCalendar === CALENDARS.vacations
        ? dashboardVacations.concat(publicHolidays)
        : dashboardAnniversaries;

    if (isFetching) {
      return (
        <div className="away-calendar">
          <CustomLoader />
        </div>
      );
    }
    return (
      <div className="away-calendar">
        <BigCalendar
          eventPropGetter={this.eventPropGetter}
          localizer={localizer}
          components={components}
          selectable
          dayPropGetter={this.dayPropGetter}
          popup
          events={displayedEvents}
          defaultView="month"
          views={['month']}
          defaultDate={this.props.calendarDate}
          scrollToTime={new Date(2008, 1, 1, 6)}
          onSelectSlot={this.onSelectSlot}
          endAccessor={this.endAccessor}
          startAccessor={this.startAccessor}
        />
        <WorkOnPublicHolidayModal
          showModal={showWorkOnPublicHolidayModal}
          toggle={this.toggleWorkOnPublicHolidayModal}
          publicHolidayName={publicHolidayName}
          publicHolidayDate={formattedPublicHolidayDate}
          onSubmit={this.onSubmitWorkOnPublicHoliday}
        />
      </div>
    );
  }
}

const mapStoreToProps = store => ({
  showRequestModal: store.ui.showRequestModal,
  dashboardVacations: store.dashboardVacations.list,
  dashboardAnniversaries: store.dashboardVacations.anniversaries,
  isFetching: store.dashboardVacations.isFetching || store.publicHolidays.isFetching,
  selectedCalendar: store.ui.selectedCalendar,
  publicHolidaysDisplayed: store.ui.publicHolidaysDisplayed,
  selectedVacationType: store.ui.selectedVacationType,
  selectedStudio: store.ui.selectedStudio,
  calendarDate: store.ui.calendarDate,
  signedInUser: store.signedInUser.user,
  userRights: store.signedInUser.userRights,
  publicHolidays:
    store.ui.publicHolidaysDisplayed === 'everyone'
      ? store.publicHolidays.list.filter(h => h.vacationType !== 'publicHolidayCountriesExtra')
      : store.ui.publicHolidaysDisplayed === 'mine'
      ? store.publicHolidays.list.filter(
          h =>
            h.vacationType !== 'publicHolidayExtra' &&
            h.vacationType !== 'publicHolidayCountriesExtra',
        )
      : store.publicHolidays.list.filter(h => h.vacationType === 'publicHolidayCountriesExtra'),
});
const mapDispatchToProps = dispatch => ({
  toggleRequestModal: bindActionCreators(toggleRequestModal, dispatch),
  setRequestModalInitialState: bindActionCreators(setRequestModalInitialState, dispatch),
  fetchAvailableVacations: bindActionCreators(fetchAvailableVacations, dispatch),
  checkWorkOnHolidayRequest: bindActionCreators(checkWorkOnHolidayRequest, dispatch),
  submitWorkOnHolidayRequest: bindActionCreators(submitWorkOnHolidayRequest, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(Calendar);
