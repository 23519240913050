import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import Avatar from '../../../components/ui/Avatar/index';

import './VacationRequestsCard.scss';
import DragHandle from '../../../components/ui/Draghandle';
import { withHeightTracking } from '../../../components/hoc/withHeightTracking';

const VacationRequestsCard = ({ pendingRequests, vacationRequesters, handleManage }) => {
  const teamRequstsAvatars =
    vacationRequesters &&
    vacationRequesters.map((request, i) => (
      <Avatar key={i} size="sm" title={request.name} avatar={request.avatar} />
    ));

  return (
    <div className="card-shadow vacation-requests">
      <DragHandle right={10} top={10} />
      {pendingRequests.length ? (
        <div>
          <Row className="top-row">
            <Col md="12">
              <h3>Vacation Requests</h3>
              <Button className="btn-link" onClick={handleManage}>
                Manage
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <p>
                {pendingRequests.length} pending request(s) from {vacationRequesters.length} user(s)
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="12">{teamRequstsAvatars}</Col>
          </Row>
        </div>
      ) : (
        <Row className="top-row">
          <Col md="12">
            <h3>No vacation requests</h3>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default withHeightTracking(VacationRequestsCard);
