import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPublicHolidayToDisplay } from '../../../../redux/actions/uiActions';
import {
  clearCountriesPublicHolidays,
  fetchExtraPublicHolidays,
  fetchPublicHolidays,
} from '../../../../redux/actions/publicHolidaysActions';

class OverflowMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverflowMenuOpen: false,
    };
  }

  toggleOverflowMenu = () => {
    // if no counties were selected, set the display to my holidays
    const {
      publicHolidaysCountries,
      setPublicHolidayToDisplay,
      publicHolidaysDisplayed,
      clearCountriesPublicHolidays,
    } = this.props;
    if (publicHolidaysCountries.length === 0 && publicHolidaysDisplayed === 'custom') {
      setPublicHolidayToDisplay('mine', []);
      clearCountriesPublicHolidays();
    }

    this.setState(prevState => ({
      isOverflowMenuOpen: !prevState.isOverflowMenuOpen,
    }));
  };

  fetchCountriesPublicHolidays = () => {
    const { fetchExtraPublicHolidays, date, publicHolidaysCountries } = this.props;

    const year = new Date(date).getFullYear();

    fetchExtraPublicHolidays(
      year,
      publicHolidaysCountries.map(country => country.shortName).join(','),
    );
    this.toggleOverflowMenu();
  };

  render() {
    const {
      selectComponents,
      overflowingItems,
      publicHolidaysDisplayed,
      publicHolidaysCountries,
      countries,
      setPublicHolidayToDisplay,
      clearCountriesPublicHolidays,
    } = this.props;

    return (
      <div className="select-item overflow-menu">
        <Dropdown isOpen={this.state.isOverflowMenuOpen} toggle={this.toggleOverflowMenu}>
          <DropdownToggle
            caret
            className={`away-dropdown-toggle ${this.state.isOverflowMenuOpen ? 'is-open' : ''}`}
          >
            <div className="text">
              <i className="fa fa-filter mr-1" />
              More
            </div>

            <div aria-hidden="true" className="away__indicator away__dropdown-indicator">
              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
              </svg>
            </div>
          </DropdownToggle>
          <DropdownMenu>
            {overflowingItems.map(index => (
              <div className="overflow-item" key={index}>
                <Select
                  className="away-dropdown"
                  classNamePrefix="away"
                  placeholder={selectComponents[index].placeholder}
                  value={selectComponents[index].value}
                  onChange={selectComponents[index].onChange}
                  options={selectComponents[index].options}
                  isSearchable={selectComponents[index].isSearchable}
                />
              </div>
            ))}
            {!!overflowingItems.length && <DropdownItem divider />}
            <div className="bank-holidays-control">
              <h6>Bank Holidays</h6>
              <Form>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="holidayToggle"
                      checked={publicHolidaysDisplayed === 'mine'}
                      onChange={() => {
                        clearCountriesPublicHolidays();
                        setPublicHolidayToDisplay('mine', []);
                      }}
                    />
                    Mine
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="holidayToggle"
                      checked={publicHolidaysDisplayed === 'everyone'}
                      onChange={() => {
                        clearCountriesPublicHolidays();
                        setPublicHolidayToDisplay('everyone', []);
                      }}
                    />
                    Everyone's
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name="holidayToggle"
                      checked={publicHolidaysDisplayed === 'custom'}
                      onChange={() => {
                        clearCountriesPublicHolidays();
                        setPublicHolidayToDisplay('custom', []);
                      }}
                    />
                    Custom
                  </Label>
                </FormGroup>
                {publicHolidaysDisplayed === 'custom' && (
                  <>
                    <Select
                      className="away-dropdown-form version-2 country-select"
                      classNamePrefix="away"
                      placeholder="Select countries"
                      value={publicHolidaysCountries}
                      onChange={selectedCountries => {
                        clearCountriesPublicHolidays();
                        setPublicHolidayToDisplay('custom', selectedCountries || []);
                      }}
                      options={countries}
                      isMulti
                    />
                    <Button
                      disabled={publicHolidaysCountries.length === 0}
                      onClick={this.fetchCountriesPublicHolidays}
                      className="btn"
                    >
                      Fetch Holidays
                    </Button>
                  </>
                )}
              </Form>
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

OverflowMenu.propTypes = {
  selectComponents: PropTypes.arrayOf(
    PropTypes.shape({
      placeholder: PropTypes.string,
      value: PropTypes.any,
      onChange: PropTypes.func.isRequired,
      options: PropTypes.array.isRequired,
      isSearchable: PropTypes.bool,
    }),
  ).isRequired,
  overflowingItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  publicHolidaysDisplayed: PropTypes.oneOf(['mine', 'everyone', 'custom']).isRequired,
  publicHolidaysCountries: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  setPublicHolidayToDisplay: PropTypes.func.isRequired,
  fetchPublicHolidays: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
};

const mapStoreToProps = store => ({
  publicHolidaysDisplayed: store.ui.publicHolidaysDisplayed,
  publicHolidaysCountries: store.ui.publicHolidaysCountries,
  userRights: store.signedInUser.userRights,
  publicHolidaysYearsFetched: store.publicHolidays.yearsFetched,
  countries: store.constants.countries,
});

const mapDispatchToProps = dispatch => ({
  setPublicHolidayToDisplay: bindActionCreators(setPublicHolidayToDisplay, dispatch),
  fetchPublicHolidays: bindActionCreators(fetchPublicHolidays, dispatch),
  fetchExtraPublicHolidays: bindActionCreators(fetchExtraPublicHolidays, dispatch),
  clearCountriesPublicHolidays: bindActionCreators(clearCountriesPublicHolidays, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(OverflowMenu);
