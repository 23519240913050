import React, { Fragment, Component } from 'react';
import { bindActionCreators } from 'redux';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import ProfileCard from './components/ProfileCard';
import withIsMobile from '../../components/hoc/withIsMobile';
import Header from '../../components/ui/Header';
import VacationRequestsCard from './components/VacationRequestsCard';
import UpcomingVacationsCard from './components/UpcomingVacationsCard';
import WorkingFromHomeCard from './components/WorkingFromHomeCard';
import UpcomingHolidaysCard from './components/UpcomingHolidaysCard';
import Calendar from './components/Calendar/Calendar';
import { fetchSignedInUser } from '../../redux/actions/signedInUserActions';
import { fetchTeamVacations } from '../../redux/actions/teamVacationsActions';
import { setSelectedStudio } from '../../redux/actions/uiActions';
import './components/Calendar/calendar.scss';
import {
  fetchExtraPublicHolidays,
  fetchPublicHolidays,
} from '../../redux/actions/publicHolidaysActions';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    // Load saved layout from localStorage if it exists
    const savedLayout = localStorage.getItem('dashboardCardsLayout');

    const defaultLayout = [
      { i: 'profile', x: 0, y: 0, w: 1, h: 1.7, static: false },
      { i: 'vacation-requests', x: 0, y: 1, w: 1, h: 1.8, static: false },
      { i: 'upcoming-vacations', x: 0, y: 2, w: 1, h: 1.8, static: false },
      { i: 'working-from-home', x: 0, y: 3, w: 3, h: 1.8, static: false },
      { i: 'upcoming-holidays', x: 0, y: 4, w: 4, h: 1.8, static: false },
    ];

    this.state = {
      layouts: { lg: savedLayout ? JSON.parse(savedLayout) : defaultLayout },
      componentHeights: {
        // Initialize all possible components with default heights
        profile: 180,
        'vacation-requests': 140,
        'upcoming-vacations': 180,
        'working-from-home': 150,
        'upcoming-holidays': 250,
      },
    };
  }

  recalculateLayout = currentLayout => {
    // Sort by y position to maintain order
    const sortedLayout = [...currentLayout].sort((a, b) => a.y - b.y);

    let currentY = 0;
    return sortedLayout.map(item => {
      const newItem = { ...item, y: currentY };
      currentY += item.h;
      return newItem;
    });
  };

  updateComponentHeight = (componentId, height) => {
    // update if the height has actually changed
    if (this.state.componentHeights[componentId] !== height) {
      this.setState(prevState => {
        // assuming 1 grid unit = 150px
        const gridHeight = Math.max(height / 150, 1);

        const currentLayout = prevState.layouts.lg.find(item => item.i === componentId);

        if (!currentLayout || currentLayout.h !== gridHeight) {
          //height of the changed component
          let newLayout = prevState.layouts.lg.map(item =>
            item.i === componentId ? { ...item, h: gridHeight } : item,
          );

          //recalculate all y positions
          newLayout = this.recalculateLayout(newLayout);

          return {
            componentHeights: { ...prevState.componentHeights, [componentId]: height },
            layouts: { ...prevState.layouts, lg: newLayout },
          };
        }

        return null;
      });
    }
  };

  handleLayoutChange = (layout, layouts) => {
    if (this._isUserInitiatedChange) {
      // y positions before saving
      const newLayout = this.recalculateLayout(layout);
      localStorage.setItem('dashboardCardsLayout', JSON.stringify(newLayout));
      this.setState({ layouts: { lg: newLayout } });
    }
    this._isUserInitiatedChange = false;
  };

  onDragStart = () => {
    this._isUserInitiatedChange = true;
  };

  onResizeStart = () => {
    this._isUserInitiatedChange = true;
  };

  onDragStop = layout => {
    const newLayout = this.recalculateLayout(layout);
    this.setState({ layouts: { lg: newLayout } });
  };

  onResizeStop = layout => {
    const newLayout = this.recalculateLayout(layout);
    this.setState({ layouts: { lg: newLayout } });
  };

  renderCard(key, Component, props) {
    return (
      <div key={key} className="dashboard-card-wrapper">
        <Component
          {...props}
          onHeightChange={height => this.updateComponentHeight(key, height)}
          ref={ref => (this[`${key}Ref`] = ref)}
        />
      </div>
    );
  }

  componentDidMount() {
    const {
      calendarDate,
      userRights,
      publicHolidaysYearsFetched,
      publicHolidaysCountries,
    } = this.props;
    const userStudio = this.props.studios.find(s => s.value === this.props.user.studio_id);
    this.props.setSelectedStudio(
      userStudio,
      0,
      null,
      calendarDate,
      userRights.includes('hasApproverRights'),
    );
    this.props.fetchSignedInUser();
    userRights.includes('hasApproverRights') &&
      this.props.fetchTeamVacations({
        status: 'pending',
      });

    const year = calendarDate.getFullYear();
    if (!publicHolidaysYearsFetched.includes(year)) {
      this.props.fetchPublicHolidays(year);
      this.props.fetchExtraPublicHolidays(year);
    }

    if (publicHolidaysCountries.length) {
      this.props.fetchPublicHolidays(year, publicHolidaysCountries.map(c => c.shortName).join(','));
    }
  }

  handleLayoutChange = (layout, layouts) => {
    if (this._isUserInitiatedChange) {
      const newLayout = this.recalculateLayout(layout);
      localStorage.setItem('dashboardCardsLayout', JSON.stringify(newLayout));
      this.setState({ layouts: { lg: newLayout } });
    }
    this._isUserInitiatedChange = false;
  };

  handleManage() {
    const { history } = this.props;
    history.push('/vacation-requests');
  }

  renderRightColumnCards() {
    const {
      teamVacations,
      user,
      userRights,
      upcomingPublicHolidays,
      todayVacations,
      tomorrowVacations,
      isFetching,
      workingFromHome,
      isMobile,
    } = this.props;

    const { layouts } = this.state;

    return (
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 1, md: 1, sm: 1, xs: 1, xxs: 1 }}
        onLayoutChange={this.handleLayoutChange}
        onDragStart={this.onDragStart}
        onResizeStart={this.onResizeStart}
        onDragStop={this.onDragStop}
        onResizeStop={this.onResizeStop}
        isDraggable={!isMobile}
        isResizable={false}
        preventCollision={false}
        draggableHandle=".drag-handle"
        margin={[0, 15]}
        style={{ marginTop: '-15px' }}
      >
        {this.renderCard('profile', ProfileCard, { user })}

        {userRights.includes('hasApproverRights') &&
          teamVacations &&
          this.renderCard('vacation-requests', VacationRequestsCard, {
            pendingRequests: teamVacations,
            vacationRequesters: teamVacations.filter(
              (e, i) => teamVacations.findIndex(a => a.name === e.name) === i,
            ),
            handleManage: this.handleManage.bind(this),
          })}

        {this.renderCard('upcoming-vacations', UpcomingVacationsCard, {
          todayVacations,
          tomorrowVacations,
          isFetching,
        })}

        {this.renderCard('working-from-home', WorkingFromHomeCard, {
          workingFromHome,
          isFetching,
        })}

        {this.renderCard('upcoming-holidays', UpcomingHolidaysCard, { upcomingPublicHolidays })}
      </ResponsiveGridLayout>
    );
  }

  render() {
    const { userRights, isMobile } = this.props;

    return (
      <Fragment>
        <Header title="Dashboard" hasRequestDay />
        <Row>
          <Col md="8">
            <div
              className={cx(
                'card-shadow calendar',
                userRights.includes('hasApproverRights') && isMobile && 'admin-mobile',
              )}
            >
              <Calendar />
            </div>
          </Col>

          <Col md="4">{this.renderRightColumnCards()}</Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStoreToProps = store => ({
  studios: store.constants.studios,
  user: store.signedInUser.user,
  dashboardVacations: store.dashboardVacations.list,
  todayVacations: store.dashboardVacations.todayVacations,
  tomorrowVacations: store.dashboardVacations.tomorrowVacations,
  workingFromHome: store.dashboardVacations.workingFromHome,
  isFetching: store.dashboardVacations.isFetching,
  teamVacations: store.teamVacations.list,
  upcomingPublicHolidays: store.constants.upcomingPublicHolidays,
  calendarDate: store.ui.calendarDate,
  selectedVacationType: store.ui.selectedVacationType,
  selectedStudio: store.ui.selectedStudio,
  selectedTeam: store.ui.selectedTeam,
  userRights: store.signedInUser.userRights,
  publicHolidaysYearsFetched: store.publicHolidays.yearsFetched,
  publicHolidaysCountries: store.ui.publicHolidaysCountries,
});

const mapDispatchToProps = dispatch => ({
  fetchPublicHolidays: bindActionCreators(fetchPublicHolidays, dispatch),
  fetchExtraPublicHolidays: bindActionCreators(fetchExtraPublicHolidays, dispatch),
  fetchTeamVacations: bindActionCreators(fetchTeamVacations, dispatch),
  fetchSignedInUser: bindActionCreators(fetchSignedInUser, dispatch),
  setSelectedStudio: bindActionCreators(setSelectedStudio, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(withIsMobile(DashboardContainer));
