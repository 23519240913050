import React, { Fragment } from 'react';
import { Row, Col, Popover, PopoverBody } from 'reactstrap';
import chroma from 'chroma-js';
import { formatDate } from '../../../../utils/Date';
import { CALENDAR_COLORS } from '../../../../constants';

class Event extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleMouseHover = this.handleMouseHover.bind(this);
    this.state = {
      popoverOpen: false,
      isHovering: false,
    };
  }

  handleMouseHover() {
    this.setState(this.toggleHoverState);
  }

  toggleHoverState(state) {
    return {
      isHovering: !state.isHovering,
    };
  }

  toggle = event => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
    event.stopPropagation();
  };

  renderPublicHolidayWithPopover = vacationStyle => {
    const { event } = this.props;
    const { popoverOpen } = this.state;
    const formattedName = event.name.split('(')[0].trim();

    return (
      <>
        <div
          className="holiday-row"
          style={{ marginLeft: 0 }}
          id={'Popover-' + event.id}
          onClick={this.toggle}
        >
          <div style={vacationStyle} />
          <p style={{ cursor: 'pointer' }}>{event.name}</p>
        </div>

        <Popover
          placement="top"
          isOpen={popoverOpen}
          target={'Popover-' + event.id}
          toggle={this.toggle}
          className="calendar-popover"
        >
          <PopoverBody>
            <div style={{ maxWidth: 200 }}>
              <h5
                style={{
                  color: 'rgba(255, 23, 68, 1)',
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
              >
                {formattedName}
              </h5>

              <p style={{ color: 'rgba(255, 23, 68, 0.7)', fontSize: '12px' }}>
                Celebrated in: {event.extraCounties}
              </p>
            </div>
          </PopoverBody>
        </Popover>
      </>
    );
  };

  renderPublicHoliday = vacationStyle => {
    const { event } = this.props;
    if (event.extraCounties) {
      return this.renderPublicHolidayWithPopover(vacationStyle);
    }
    return (
      <div className="holiday-row" style={{ marginLeft: 0 }}>
        <div style={vacationStyle} />
        <p>{event.name}</p>
      </div>
    );
  };

  renderEvents = (vacationStyle, userRights) => {
    const { event } = this.props;
    const { popoverOpen, isHovering } = this.state;
    return (
      <Fragment>
        <Row
          onMouseEnter={this.handleMouseHover}
          onMouseLeave={this.handleMouseHover}
          id={
            'Popover-' +
            event.id
              .toString()
              .replace('(', '')
              .replace(')', '')
          }
          onClick={this.toggle}
          className={event.vacationType}
          style={
            isHovering
              ? {
                  backgroundColor: chroma(event.vacationTypeColor)
                    .alpha(0.5)
                    .css(),
                }
              : null
          }
        >
          <div className="event-border" style={vacationStyle} />
          <p className="user-name">{event.name}</p>
        </Row>
        <Popover
          placement="top"
          isOpen={popoverOpen}
          target={
            'Popover-' +
            event.id
              .toString()
              .replace('(', '')
              .replace(')', '')
          }
          toggle={this.toggle}
          className="calendar-popover"
        >
          <PopoverBody>
            <div>
              <h3>{event.name}</h3>
              <Row className="row-margin">
                <Col xs={6}>
                  <label>Vacation Type</label>
                  <p style={{ color: event.vacationTypeColor, textTransform: 'capitalize' }}>
                    {event.vacationType}
                  </p>
                </Col>
                <Col xs={6}>
                  <label>Period</label>
                  <p>
                    <span>{formatDate(event.startDate, 'MMM D')}</span>
                    <span> - </span>
                    <span>{formatDate(event.endDate, 'MMM D')}</span>
                  </p>
                </Col>
              </Row>
              <Row>
                {userRights.includes('hasApproverRights') && (
                  <Col xs={6}>
                    <label>Days Left</label>
                    <p>{event.daysLeft}</p>
                  </Col>
                )}
                <Col xs={6}>
                  <label>Approved by</label>
                  <p>{event.approvedBy}</p>
                </Col>
              </Row>
            </div>
          </PopoverBody>
        </Popover>
      </Fragment>
    );
  };

  renderAnniversaries = () => {
    const { event } = this.props;
    const { popoverOpen, isHovering } = this.state;
    const { type, years, name, popoverId } = event;

    const isFirstYear = type === 'anniversary' && years === 0;

    const anniversaryBgColor = isFirstYear
      ? CALENDAR_COLORS.firstYearAnniversary
      : CALENDAR_COLORS.anniversary;

    const backgroundColor = type === 'anniversary' ? anniversaryBgColor : CALENDAR_COLORS.birthday;

    const birthdayLabel = name;
    const anniversaryLabel = isFirstYear ? name : `${name} (${years})`;
    const label = type === 'birthday' ? birthdayLabel : anniversaryLabel;

    const birthdayPopoverTitle = `${name}'s birthday 🎂`;
    const anniversaryPopoverTitle = isFirstYear
      ? `${name}'s first day 😎`
      : `${name}'s anniversary 🍰`;
    const popoverTitle = type === 'birthday' ? birthdayPopoverTitle : anniversaryPopoverTitle;

    return (
      <Fragment>
        <Row
          onMouseEnter={this.handleMouseHover}
          onMouseLeave={this.handleMouseHover}
          id={popoverId}
          onClick={this.toggle}
          style={
            isHovering
              ? {
                  backgroundColor: chroma(backgroundColor)
                    .alpha(0.6)
                    .css(),
                  borderTopRightRadius: '20px',
                  borderBottomRightRadius: '20px',
                }
              : null
          }
        >
          <p className="user-name" style={{ color: '#fff' }}>
            {label}
          </p>
        </Row>
        <Popover
          placement="top"
          isOpen={popoverOpen}
          target={popoverId}
          toggle={this.toggle}
          className="calendar-popover"
        >
          <PopoverBody>
            <div>
              <h3>{popoverTitle}</h3>
              <Row className="row-margin">
                <Col xs={12}>
                  {type === 'birthday' && <p>Let's celebrate him/her.</p>}
                  {type === 'anniversary' && isFirstYear && <p>Feel free to introduce yourself.</p>}
                  {type === 'anniversary' && !isFirstYear && (
                    <Fragment>
                      <p style={{ color: '#2176dccc' }}>They've been with us for:</p>
                      <p>{years} year(s)</p>
                    </Fragment>
                  )}
                </Col>
              </Row>
            </div>
          </PopoverBody>
        </Popover>
      </Fragment>
    );
  };

  render() {
    const { event } = this.props;
    const vacationStyle = {
      backgroundColor: event.vacationTypeColor,
      width: 4,
    };
    if (
      event.vacationType === 'publicHoliday' ||
      event.vacationType === 'publicHolidayExtra' ||
      event.vacationType === 'publicHolidayCountriesExtra'
    ) {
      return this.renderPublicHoliday(vacationStyle);
    } else if (event.type === 'birthday' || event.type === 'anniversary') {
      return this.renderAnniversaries();
    }
    // GDPR: if signed in user is regular, don't show days left
    return this.renderEvents(vacationStyle, event.userRights);
  }
}

export default Event;
