import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import Avatar from '../../../components/ui/Avatar';
import './UpcomingVacationsCard.scss';
import CustomLoader from '../../../components/ui/Loader';
import DragHandle from '../../../components/ui/Draghandle';
import { withHeightTracking } from '../../../components/hoc/withHeightTracking';

const UpcomingVacationsCard = ({ todayVacations, tomorrowVacations, isFetching }) => {
  const todayRequestsAvatars =
    todayVacations &&
    todayVacations.map((request, i) => {
      return (
        <Avatar
          key={i}
          size="sm"
          title={request.name}
          avatar={request.avatar}
          userId={request.userId}
          isLink
        />
      );
    });

  const tomorrowRequestsAvatars =
    tomorrowVacations &&
    tomorrowVacations.map((request, i) => {
      return (
        <Avatar
          key={i}
          size="sm"
          title={request.name}
          avatar={request.avatar}
          userId={request.userId}
          isLink
        />
      );
    });

  return (
    <div className="card-shadow upcoming-vacations">
      <DragHandle right={10} top={10} />
      {isFetching ? (
        <div>
          <CustomLoader />
        </div>
      ) : (
        <Fragment>
          <Row className="top-row">
            <Col md="12">
              <h3>On Vacation</h3>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <p className="when">Today</p>
            </Col>
          </Row>

          {todayRequestsAvatars.length ? (
            <Row>
              <Col md="12">{todayRequestsAvatars}</Col>
            </Row>
          ) : (
            <Row className="top-row">
              <Col md="12">
                <h6>No vacations today!</h6>
              </Col>
            </Row>
          )}

          <Row>
            <Col md="12">
              <p className="when">Tomorrow</p>
            </Col>
          </Row>

          {tomorrowRequestsAvatars.length ? (
            <Row>
              <Col md="12">{tomorrowRequestsAvatars}</Col>
            </Row>
          ) : (
            <Row className="top-row">
              <Col md="12">
                <h6>No vacations tomorrow!</h6>
              </Col>
            </Row>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default withHeightTracking(UpcomingVacationsCard);
