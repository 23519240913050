import React, { Component } from 'react';
import Select from 'react-select';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import {
  setSelectedVacation,
  setSelectedStudio,
  setSelectedTeam,
  toggleSelectedCalendar,
  setPublicHolidayToDisplay,
} from '../../../../redux/actions/uiActions';
import { firstDayOfAMonth, lastDayOfAMonth } from '../../../../utils/Date';
import {
  fetchDashboardVacations,
  fetchDashboardAnniversaries,
} from '../../../../redux/actions/dashboardVacationsActions';
import { CALENDARS } from '../../../../constants';
import './Toolbar.scss';
import {
  fetchExtraPublicHolidays,
  fetchPublicHolidays,
} from '../../../../redux/actions/publicHolidaysActions';
import SwitchSelector from '../../../../components/ui/SwitchSelector';
import OverflowMenu from './OverflowMenu';

class Toolbar extends Component {
  state = {
    overflowingItems: [],
  };

  containerRef = React.createRef();
  itemRefs = [];

  componentDidMount() {
    this.debouncedCheckOverflow = this.debounce(this.checkOverflow, 200);
    window.addEventListener('resize', this.debouncedCheckOverflow);
    this.checkOverflow();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedCheckOverflow);
  }

  debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  checkOverflow = () => {
    const container = this.containerRef.current;
    if (!container) return;

    const containerWidth = container.clientWidth;
    const menuButtonWidth = 50; // Adjust based on your actual menu button width
    const availableWidth = containerWidth - menuButtonWidth;

    // Reset overflow state
    this.setState({ overflowingItems: [] }, () => {
      let currentWidth = 0;
      let newOverflowingItems = [];

      this.itemRefs.forEach((ref, index) => {
        if (!ref) return;
        const itemWidth = ref.clientWidth;

        if (currentWidth + itemWidth > availableWidth) {
          newOverflowingItems.push(index);
        } else {
          currentWidth += itemWidth;
        }
      });

      // Update state only if the overflowing items have changed
      this.setState(prevState => {
        if (JSON.stringify(prevState.overflowingItems) !== JSON.stringify(newOverflowingItems)) {
          return { overflowingItems: newOverflowingItems };
        }
        return null;
      });
    });
  };

  goToBack = () => {
    const {
      date,
      onNavigate,
      userRights,
      publicHolidaysYearsFetched,
      publicHolidaysCountries,
    } = this.props;
    const initialYear = date.getFullYear();
    date.setDate(0);
    this.checkForToday();
    this.fetchEvents(date);

    const year = date.getFullYear();
    if (initialYear !== year && !publicHolidaysYearsFetched.includes(year)) {
      this.props.fetchPublicHolidays(year);
      this.props.fetchExtraPublicHolidays(year);
    }

    if (initialYear !== year && publicHolidaysCountries.length) {
      this.props.fetchExtraPublicHolidays(
        year,
        publicHolidaysCountries.map(c => c.shortName).join(','),
      );
    }

    userRights.includes('hasApproverRights') && this.fetchAnniversaries(date);
    onNavigate('prev');
  };

  fetchEvents = date => {
    const firstDay = firstDayOfAMonth(date);
    const lastDay = lastDayOfAMonth(date);
    lastDayOfAMonth(date);
    this.props.fetchDashboardVacations(
      firstDay,
      lastDay,
      this.props.selectedVacationType ? this.props.selectedVacationType.value : 0,
      this.props.selectedStudio ? this.props.selectedStudio.value : 0,
      this.props.selectedTeam ? this.props.selectedTeam.value : 0,
    );
  };

  fetchAnniversaries = date => {
    const firstDay = firstDayOfAMonth(date);
    const lastDay = lastDayOfAMonth(date);
    lastDayOfAMonth(date);
    this.props.fetchDashboardAnniversaries(
      firstDay,
      lastDay,
      this.props.selectedStudio ? this.props.selectedStudio.value : 0,
      this.props.selectedTeam ? this.props.selectedTeam.value : 0,
    );
  };

  goToNext = () => {
    const {
      date,
      onNavigate,
      userRights,
      publicHolidaysYearsFetched,
      publicHolidaysCountries,
    } = this.props;
    const initialYear = date.getFullYear();
    date.setDate(32);
    this.checkForToday();
    this.fetchEvents(date);

    const year = date.getFullYear();
    if (initialYear !== year && !publicHolidaysYearsFetched.includes(year)) {
      this.props.fetchPublicHolidays(year);
      this.props.fetchExtraPublicHolidays(year);
    }

    if (initialYear !== year && publicHolidaysCountries.length) {
      this.props.fetchExtraPublicHolidays(
        year,
        publicHolidaysCountries.map(c => c.shortName).join(','),
      );
    }

    userRights.includes('hasApproverRights') && this.fetchAnniversaries(date);
    onNavigate('next');
  };

  goToCurrent = () => {
    const { date, onNavigate, userRights, publicHolidaysCountries } = this.props;
    const now = new Date();
    date.setMonth(now.getMonth());
    date.setYear(now.getFullYear());
    this.fetchEvents(date);
    userRights.includes('hasApproverRights') && this.fetchAnniversaries(date);

    if (publicHolidaysCountries.length) {
      this.props.fetchExtraPublicHolidays(
        now.getFullYear(),
        publicHolidaysCountries.map(c => c.shortName).join(','),
      );
    }

    onNavigate('current');
  };

  checkForToday = () => {
    const { date } = this.props;
    const calendarMonth = date.getMonth();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDate();
    if (calendarMonth === currentMonth) {
      date.setDate(currentDay);
    }
  };

  label = () => {
    const { date } = this.props;
    const dt = moment(date);
    return (
      <span>
        <b>{dt.format('MMMM')}</b>
        <span className="year"> {dt.format('YYYY')}</span>
      </span>
    );
  };

  handleSelectVacationType = selectedOption => {
    const { date } = this.props;
    this.props.setSelectedVacation(
      selectedOption,
      this.props.selectedStudio,
      this.props.selectedTeam,
      date,
    );
  };

  handleSelectStudio = selectedOption => {
    const { date, userRights } = this.props;
    this.props.setSelectedStudio(
      selectedOption,
      this.props.selectedTeam,
      this.props.selectedVacationType,
      date,
      userRights.includes('hasApproverRights'),
    );
  };

  handleSelectTeam = selectedOption => {
    const { date, userRights } = this.props;
    this.props.setSelectedTeam(
      this.props.selectedStudio,
      selectedOption,
      this.props.selectedVacationType,
      date,
      userRights.includes('hasApproverRights'),
    );
  };

  toggleAnniversaries = () => {
    // toggles between vacations and anniversaries
    this.props.toggleSelectedCalendar();
  };

  render() {
    const {
      publicHolidaysDisplayed,
      publicHolidaysCountries,
      selectedCalendar,
      userRights,
      date,
    } = this.props;
    const { overflowingItems } = this.state;

    const isVacationsCalendar = selectedCalendar === CALENDARS.vacations;

    const publicHolidaysLabel = `Bank holidays displayed: ${
      publicHolidaysDisplayed === 'mine'
        ? 'mine'
        : publicHolidaysDisplayed === 'everyone'
        ? "everyone's"
        : publicHolidaysCountries.map(country => country.shortName).join(', ') || 'selecting...'
    }`;

    const selectComponents = [
      {
        placeholder: 'Vacation type',
        value: this.props.selectedVacationType,
        onChange: this.handleSelectVacationType,
        options: [
          { label: 'All vacations', value: 0 },
          ...this.props.vacationTypes,
          { label: 'Working on Holiday', value: -2 },
        ],
        isSearchable: false,
      },
      {
        placeholder: 'Studios',
        value: this.props.selectedStudio,
        onChange: this.handleSelectStudio,
        options: [{ label: 'All Studios', value: 0 }, ...this.props.studios],
        isSearchable: false,
      },
      {
        placeholder: 'Teams',
        value: this.props.selectedTeam,
        onChange: this.handleSelectTeam,
        options: [{ label: 'All Teams', value: 0 }, ...this.props.teams],
        isSearchable: false,
      },
    ];

    return (
      <div className="toolbar-container">
        <Row>
          <div className="toolbar-togglers">
            {isVacationsCalendar && (
              <button className="btn btn-link toggleAllPublicHolidays" disabled>
                {publicHolidaysLabel}
              </button>
            )}
            {userRights.includes('hasApproverRights') && (
              <SwitchSelector
                option1={{ label: 'Vacations', value: 'vacations' }}
                option2={{ label: 'Anniversaries', value: 'anniversaries' }}
                selected={isVacationsCalendar ? 'option1' : 'option2'}
                onChange={this.toggleAnniversaries}
              />
            )}
          </div>
          <Col xs={4}>
            <div className="back-next-buttons">
              <button className="btn btn-back btn-link" onClick={this.goToBack}>
                ❮
              </button>
              <div className="toolbar-date">
                <button
                  className="btn btn-today btn-link"
                  onClick={this.goToCurrent}
                  title="Go Today"
                >
                  <i className="fa fa-calendar" aria-hidden="true" />
                </button>
                <label className="label-date">{this.label()}</label>
              </div>
              <button className="btn btn-next btn-link" onClick={this.goToNext}>
                ❯
              </button>
            </div>
          </Col>
          <Col xs={8}>
            <div className="filters-container" ref={this.containerRef}>
              {selectComponents.map((selectProps, index) => (
                <div
                  key={index}
                  className="select-item"
                  ref={ref => (this.itemRefs[index] = ref)}
                  style={{ display: overflowingItems.includes(index) ? 'none' : 'block' }}
                >
                  <Select
                    className="away-dropdown"
                    classNamePrefix="away"
                    placeholder={selectProps.placeholder}
                    value={selectProps.value}
                    onChange={selectProps.onChange}
                    options={selectProps.options}
                    isSearchable={selectProps.isSearchable}
                  />
                </div>
              ))}

              <OverflowMenu
                selectComponents={selectComponents}
                overflowingItems={overflowingItems}
                date={date}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStoreToProps = store => ({
  vacationTypes: store.constants.vacationTypes,
  studios: store.constants.studios,
  teams: store.constants.teams,
  selectedVacationType: store.ui.selectedVacationType,
  selectedStudio: store.ui.selectedStudio,
  selectedTeam: store.ui.selectedTeam,
  selectedCalendar: store.ui.selectedCalendar,
  publicHolidaysDisplayed: store.ui.publicHolidaysDisplayed,
  publicHolidaysCountries: store.ui.publicHolidaysCountries,
  userRights: store.signedInUser.userRights,
  publicHolidaysYearsFetched: store.publicHolidays.yearsFetched,
});

const mapDispatchToProps = dispatch => ({
  setSelectedStudio: bindActionCreators(setSelectedStudio, dispatch),
  setSelectedVacation: bindActionCreators(setSelectedVacation, dispatch),
  setSelectedTeam: bindActionCreators(setSelectedTeam, dispatch),
  toggleSelectedCalendar: bindActionCreators(toggleSelectedCalendar, dispatch),
  setPublicHolidayToDisplay: bindActionCreators(setPublicHolidayToDisplay, dispatch),
  fetchDashboardVacations: bindActionCreators(fetchDashboardVacations, dispatch),
  fetchDashboardAnniversaries: bindActionCreators(fetchDashboardAnniversaries, dispatch),
  fetchPublicHolidays: bindActionCreators(fetchPublicHolidays, dispatch),
  fetchExtraPublicHolidays: bindActionCreators(fetchExtraPublicHolidays, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(Toolbar);
