import React from 'react';
import { Component } from 'react';

// HOC to add height tracking to card components
export const withHeightTracking = WrappedComponent => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.containerRef = React.createRef();
    }

    componentDidMount() {
      this.updateHeight();
    }

    componentDidUpdate() {
      this.updateHeight();
    }

    updateHeight = () => {
      if (this.containerRef.current) {
        const height = this.containerRef.current.clientHeight;
        this.props.onHeightChange(height);
      }
    };

    render() {
      const { onHeightChange, ...passThroughProps } = this.props;
      return (
        <div ref={this.containerRef}>
          <WrappedComponent {...passThroughProps} />
        </div>
      );
    }
  };
};
