import React from 'react';
import PropTypes from 'prop-types';
import './Draghandle.scss';
import withIsMobile from '../../hoc/withIsMobile';

class DragHandle extends React.Component {
  render() {
    const { top, left, right, bottom, isMobile } = this.props;

    if (isMobile) return null;

    const style = {
      position: 'absolute',
      top: top,
      left: left,
      right: right,
      bottom: bottom,
    };

    const handleDrag = e => {
      e.preventDefault();
    };

    return (
      <div
        className="drag-handle"
        aria-label="Drag handle"
        style={style}
        onMouseDown={handleDrag}
        onTouchStart={handleDrag}
      >
        <div className="drag-handle__dots">
          <div className="drag-handle__dot" />
          <div className="drag-handle__dot" />
          <div className="drag-handle__dot" />
          <div className="drag-handle__dot" />
          <div className="drag-handle__dot" />
          <div className="drag-handle__dot" />
        </div>
      </div>
    );
  }
}

DragHandle.propTypes = {
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default withIsMobile(DragHandle);
