import {
  FETCH_PUBLIC_HOLIDAYS_ERROR,
  FETCH_PUBLIC_HOLIDAYS_SUCCESS,
  FETCH_PUBLIC_HOLIDAYS_START,
  FETCH_ALL_PUBLIC_HOLIDAYS_ERROR,
  FETCH_ALL_PUBLIC_HOLIDAYS_START,
  FETCH_ALL_PUBLIC_HOLIDAYS_SUCCESS,
  FETCH_PUBLIC_HOLIDAYS_YEAR_SUCCESS,
  FETCH_COUNTRIES_PUBLIC_HOLIDAYS_SUCCESS,
  CLEAR_COUNTRIES_PUBLIC_HOLIDAYS,
} from '../actions/types';

const initialValues = {
  isFetching: false,
  list: [],
  yearsFetched: [],
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case FETCH_PUBLIC_HOLIDAYS_START:
    case FETCH_ALL_PUBLIC_HOLIDAYS_START:
      return { ...state, isFetching: true };
    case FETCH_PUBLIC_HOLIDAYS_SUCCESS: {
      return {
        ...state,
        list: [
          ...state.list,
          ...action.payload.map(holiday => ({
            date: holiday.date,
            startDate: holiday.date,
            endDate: holiday.date,
            name: holiday.localName,
            vacationType: 'publicHoliday',
            vacationTypeColor: '#ff1744',
          })),
        ],
        isFetching: false,
      };
    }

    case FETCH_COUNTRIES_PUBLIC_HOLIDAYS_SUCCESS: {
      return {
        ...state,
        list: [
          ...state.list.filter(holiday => holiday.vacationType !== 'publicHolidayCountriesExtra'),
          ...action.payload.map((holiday, i) => ({
            id: 'public_holiday_' + i,
            date: holiday.date,
            startDate: holiday.date,
            endDate: holiday.date,
            name: holiday.localName,
            extraCounties: holiday.extraCounties,
            vacationType: 'publicHolidayCountriesExtra',
            vacationTypeColor: 'rgba(255, 23, 68, 0.4)',
          })),
        ],
        isFetching: false,
      };
    }

    case CLEAR_COUNTRIES_PUBLIC_HOLIDAYS:
      return {
        ...state,
        list: state.list.filter(holiday => holiday.vacationType !== 'publicHolidayCountriesExtra'),
      };

    case FETCH_ALL_PUBLIC_HOLIDAYS_SUCCESS: {
      return {
        ...state,
        list: [
          ...state.list,
          ...action.payload.map(holiday => ({
            date: holiday.date,
            startDate: holiday.date,
            endDate: holiday.date,
            name: holiday.localName,
            vacationType: 'publicHolidayExtra',
            vacationTypeColor: 'rgba(255, 23, 68, 0.4)',
          })),
        ],
        isFetching: false,
      };
    }
    case FETCH_PUBLIC_HOLIDAYS_YEAR_SUCCESS: {
      return {
        ...state,
        yearsFetched: [...state.yearsFetched, action.payload.year],
      };
    }
    case FETCH_PUBLIC_HOLIDAYS_ERROR:
    case FETCH_ALL_PUBLIC_HOLIDAYS_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    default:
      return state;
  }
};
