import React from 'react';
import PropTypes from 'prop-types';
import './SwitchSelector.scss';

class SwitchSelector extends React.Component {
  constructor(props) {
    super(props);
    this.option1Ref = React.createRef();
    this.option2Ref = React.createRef();
  }

  componentDidMount() {
    this.updateSliderStyle();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected !== this.props.selected) {
      this.updateSliderStyle();
    }
  }

  updateSliderStyle = () => {
    const activeRef = this.props.selected === 'option1' ? this.option1Ref : this.option2Ref;
    if (activeRef.current) {
      const slider = activeRef.current.parentNode.querySelector('.slider');
      const option = activeRef.current;
      const optionRect = option.getBoundingClientRect();

      slider.style.width = `${optionRect.width}px`;
      if (this.props.selected === 'option2') {
        slider.style.transform = `translateX(${optionRect.width}px)`;
      } else {
        slider.style.transform = 'translateX(0)';
      }
    }
  };

  handleSelect = option => {
    if (this.props.onChange) {
      const selectedOption = option === 'option1' ? this.props.option1 : this.props.option2;
      this.props.onChange(selectedOption);
    }
  };

  render() {
    const { option1, option2, selected } = this.props;

    return (
      <div className="switch-container">
        <div className={`switch ${selected}`}>
          <div
            ref={this.option1Ref}
            className={`option ${selected === 'option1' ? 'selected' : ''}`}
            onClick={() => this.handleSelect('option1')}
          >
            {option1.label}
          </div>
          <div
            ref={this.option2Ref}
            className={`option ${selected === 'option2' ? 'selected' : ''}`}
            onClick={() => this.handleSelect('option2')}
          >
            {option2.label}
          </div>
          <div className="slider" />
        </div>
      </div>
    );
  }
}

SwitchSelector.propTypes = {
  option1: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  }).isRequired,
  option2: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  }).isRequired,
  selected: PropTypes.oneOf(['option1', 'option2']).isRequired,
  onChange: PropTypes.func,
};

export default SwitchSelector;
