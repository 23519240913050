import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import Avatar from '../../../components/ui/Avatar';
import CustomLoader from '../../../components/ui/Loader';
import './WorkingFromHomeCard.scss';
import DragHandle from '../../../components/ui/Draghandle';
import { withHeightTracking } from '../../../components/hoc/withHeightTracking';

const WorkingFromHomeCard = ({ workingFromHome, isFetching }) => {
  const workingFromHomeAvatars =
    workingFromHome &&
    workingFromHome.map((profile, i) => {
      return (
        <Avatar
          key={i}
          size="sm"
          title={profile.name}
          avatar={profile.avatar}
          userId={profile.userId}
          isLink
        />
      );
    });

  return (
    <div className="card-shadow working-from-home">
      <DragHandle right={10} top={10} />
      {isFetching ? (
        <div>
          <CustomLoader />
        </div>
      ) : (
        <Fragment>
          <Row className="top-row">
            <Col md="12">
              <h3>Working From Home</h3>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <p className="when">Today</p>
            </Col>
          </Row>

          {workingFromHomeAvatars.length ? (
            <Row>
              <Col md="12">{workingFromHomeAvatars}</Col>
            </Row>
          ) : (
            <Row className="top-row">
              <Col md="12">
                <h6>No people currently working from home!</h6>
              </Col>
            </Row>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default withHeightTracking(WorkingFromHomeCard);
